export const SurveyMappingMixin = {
  computed: {
    code () {
      return this.$route.params?.code
    },
    survey () {
      return this.$store.getters.survey(this.code)
    },
  }
}
